import { useRef, useState } from 'react';

import jwtDecode from 'jwt-decode';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';

import Iconify from '../../../components/iconify';
import OrderDetails from './OrderDetails';

export default function UserMoreMenu({
  id,
  onHandleConfirm,
  onHandleReject,
  onHandleCancel,
  onHandleDelete,
  order,
  getOrders,
}) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const handleClose = (e, reason) => {
    if (reason && reason === 'backdropClick') return;
    setOpenModal(false);
  };

  const token = localStorage.getItem('token');
  const decodeToken = jwtDecode(token);

  const isAdmin = decodeToken?.role === 'admin';
  const isConfirmed = order?.status === 'confirmed';
  const isRejected = order?.status === 'rejected';
  const isCancelled = order?.status === 'cancelled';

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={() => {
            setIsOpen(false);
            handleClickOpen();
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:diagonal-arrow-right-up-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Detajet" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        {isAdmin && (
          <div>
            {!isCancelled && !isConfirmed && (
              <MenuItem
                onClick={() => {
                  setIsOpen(false);
                  onHandleConfirm(id);
                }}
              >
                <ListItemIcon>
                  <Iconify icon="eva:checkmark-outline" width={24} height={24} />
                </ListItemIcon>
                <ListItemText primary="Konfirmo" primaryTypographyProps={{ variant: 'body2' }} />
              </MenuItem>
            )}

            {!isCancelled && !isRejected && (
              <MenuItem
                onClick={() => {
                  setIsOpen(false);
                  onHandleReject(id);
                }}
              >
                <ListItemIcon>
                  <Iconify icon="eva:close-outline" width={24} height={24} />
                </ListItemIcon>
                <ListItemText primary="Refuzo" primaryTypographyProps={{ variant: 'body2' }} />
              </MenuItem>
            )}

            <MenuItem
              onClick={() => {
                setIsOpen(false);
                onHandleDelete(id);
              }}
            >
              <ListItemIcon>
                <Iconify icon="eva:trash-2-outline" width={24} height={24} />
              </ListItemIcon>
              <ListItemText primary="Fshij" primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
          </div>
        )}

        {!isAdmin && !isConfirmed && !isRejected && !isCancelled && (
          <MenuItem
            onClick={() => {
              setIsOpen(false);
              onHandleCancel(id);
            }}
          >
            <ListItemIcon>
              <Iconify icon="eva:close-outline" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Anulo" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
      </Menu>

      <OrderDetails
        openModal={openModal}
        onHandleClose={handleClose}
        order={order}
        getOrders={getOrders}
        onHandleConfirm={onHandleConfirm}
        onHandleReject={onHandleReject}
        isAdmin={isAdmin}
        isConfirmed={isConfirmed}
        isRejected={isRejected}
        isCancelled={isCancelled}
      />
    </>
  );
}
