import { createRef, useRef } from 'react';
import jwtDecode from 'jwt-decode';

import { Table, TableRow, TableBody, TableCell, TableContainer, TableHead, Button } from '@mui/material';

import { useAppContext } from '../../../context/app-context';
import Scrollbar from '../../../components/scrollbar';
import { fCurrency } from '../../../utils/formatNumber';
import Iconify from '../../../components/iconify';

export default function ProductList({ products }) {
  const { addToCart } = useAppContext();
  const ref = useRef([]);

  ref.current = products.map((e, i) => ref.current[i] ?? createRef());

  const TABLE_HEAD = [
    { id: 'manufacturerAndModel', label: 'Prodhuesi dhe modeli', alignRight: false },
    { id: 'dimensions', label: 'Dimensionet', alignRight: false },
    { id: 'price', label: 'Çmimi', alignRight: false },
    { id: 'quantity', label: 'Sasia', alignRight: false },
    { id: 'season', label: 'Sezona', alignRight: false },
    { id: '', label: <Iconify icon="eva:shopping-cart-outline" /> },
  ];

  const seasons = { V: 'Verë', D: 'Dimër', VD: 'Verë - Dimër', A: 'All seasons' };

  const token = localStorage.getItem('token');
  const decodeToken = jwtDecode(token);

  const isAdmin = decodeToken?.role === 'admin';

  return (
    <Scrollbar>
      <TableContainer sx={{ minWidth: 800 }}>
        <Table>
          <TableHead>
            <TableRow>
              {TABLE_HEAD.map((headCell) => (
                <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {products.map((product, i) => {
              const { productId, manufacturer, model, price, season, quantity, dimension } = product;

              return (
                <TableRow key={productId}>
                  <TableCell align="left">{`${manufacturer} ${model}`}</TableCell>
                  <TableCell align="left">{dimension} </TableCell>
                  <TableCell align="left">{fCurrency(price)} </TableCell>
                  <TableCell align="left">{isAdmin ? quantity : quantity > 0 ? 'Ka' : 'Ska'}</TableCell>
                  <TableCell align="left">{seasons[season]}</TableCell>
                  <TableCell align="left">
                    <input
                      type="number"
                      min={0}
                      max="1000"
                      ref={ref?.current[i]}
                      className="AddToCartInput"
                      placeholder="1"
                      disabled={quantity === 0 && true}
                    />
                    <Button
                      onClick={() => addToCart({ ...product, qty: Number(ref?.current[i]?.current?.value) || 1 })}
                      size="large"
                      type="submit"
                      color="success"
                      disabled={quantity === 0 && true}
                      style={{ height: '29px' }}
                    >
                      Shto
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Scrollbar>
  );
}
