import { Helmet } from 'react-helmet-async';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';

import { useAppContext } from '../context/app-context';
import { LoginForm } from '../sections/auth/login';

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function LoginPage() {
  const { user } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.role === 'admin') {
      setTimeout(() => {
        navigate('/dashboard/orders');
      }, 1000);
    }
    if (user?.role === 'user') {
      setTimeout(() => {
        navigate('/dashboard/products');
      }, 1000);
    }
  }, [user, navigate]);

  return (
    <>
      <Helmet>
        <title> Kyçuni </title>
      </Helmet>

      <Container maxWidth="sm">
        <StyledContent>
          <Typography variant="h4" gutterBottom align="center">
            Kyçuni
          </Typography>

          <Typography variant="body2" align="center" sx={{ mb: 3 }}>
            Vendosni të dhënat tuaja më poshtë
          </Typography>

          <LoginForm />
        </StyledContent>
      </Container>
    </>
  );
}
