import { MenuItem, Select, ListItemText, OutlinedInput, FormControl, InputLabel, Checkbox } from '@mui/material';

const SORT_BY_OPTIONS = [
  { value: 'ADVANCE', label: 'ADVANCE' },
  { value: 'AMBERSTONE', label: 'AMBERSTONE' },
  { value: 'ANNAITE', label: 'ANNAITE' },
  { value: 'ANTARES', label: 'ANTARES' },
  { value: 'AOTELI', label: 'AOTELI' },
  { value: 'AUSTONE', label: 'AUSTONE' },
  { value: 'BARUM', label: 'BARUM' },
  { value: 'BF GOODRICH', label: 'BF GOODRICH' },
  { value: 'BRIDGESTONE', label: 'BRIDGESTONE' },
  { value: 'BRONCO', label: 'BRONCO' },
  { value: 'DEBICA', label: 'DEBICA' },
  { value: 'CONTINENTAL', label: 'CONTINENTAL' },
  { value: 'DELINTE', label: 'DELINTE' },
  { value: 'DOUBLE COIN', label: 'DOUBLE COIN' },
  { value: 'DUNLOP', label: 'DUNLOP' },
  { value: 'EKKA', label: 'EKKA' },
  { value: 'EVERGREEN', label: 'EVERGREEN' },
  { value: 'FARUNNER', label: 'FARUNNER' },
  { value: 'FIRESTONE', label: 'FIRESTONE' },
  { value: 'FORERUNNER', label: 'FORERUNNER' },
  { value: 'FORMULA', label: 'FORMULA' },
  { value: 'GISLAVED', label: 'GISLAVED' },
  { value: 'GOFORM', label: 'GOFORM' },
  { value: 'GOLDSHIELD', label: 'GOLDSHIELD' },
  { value: 'GOOD TUBE', label: 'GOOD TUBE' },
  { value: 'GOODYEAR', label: 'GOODYEAR' },
  { value: 'GRIPMAX', label: 'GRIPMAX' },
  { value: 'HANKOOK', label: 'HANKOOK' },
  { value: 'HEADWAY', label: 'HEADWAY' },
  { value: 'HIFLY', label: 'HIFLY' },
  { value: 'HUGEL', label: 'HUGEL' },
  { value: 'ILINK', label: 'ILINK' },
  { value: 'INFINITY', label: 'INFINITY' },
  { value: 'KAPSEN', label: 'KAPSEN' },
  { value: 'KELLY', label: 'KELLY' },
  { value: 'KINFOREST', label: 'KINFOREST' },
  { value: 'KORYO', label: 'KORYO' },
  { value: 'KPATOS', label: 'KPATOS' },
  { value: 'LAKESEA', label: 'LAKESEA' },
  { value: 'LANDSAIL', label: 'LANDSAIL' },
  { value: 'LANVIGATOR', label: 'LANVIGATOR' },
  { value: 'LINGLONG', label: 'LINGLONG' },
  { value: 'LONG MARCH', label: 'LONG MARCH' },
  { value: 'MAXAUTO', label: 'MAXAUTO' },
  { value: 'MAXTREK', label: 'MAXTREK' },
  { value: 'MICHELIN', label: 'MICHELIN' },
  { value: 'MIRAGE', label: 'MIRAGE' },
  { value: 'NEXEN', label: 'NEXEN' },
  { value: 'ONYX', label: 'ONYX' },
  { value: 'OVATION', label: 'OVATION' },
  { value: 'PETLAS', label: 'PETLAS' },
  { value: 'PIRELLI', label: 'PIRELLI' },
  { value: 'POWERTRAC', label: 'POWERTRAC' },
  { value: 'PROTECTOR', label: 'PROTECTOR' },
  { value: 'RADAR', label: 'RADAR' },
  { value: 'RAPID', label: 'RAPID' },
  { value: 'RIKEN', label: 'RIKEN' },
  { value: 'ROADGUIDER', label: 'ROADGUIDER' },
  { value: 'SAFFIRO', label: 'SAFFIRO' },
  { value: 'SAMSON', label: 'SAMSON' },
  { value: 'SEMPERIT', label: 'SEMPERIT' },
  { value: 'SONNY', label: 'SONNY' },
  { value: 'STARFIRE', label: 'STARFIRE' },
  { value: 'STRIAL', label: 'STRIAL' },
  { value: 'SUNFULL', label: 'SUNFULL' },
  { value: 'SUPERGUIDER', label: 'SUPERGUIDER' },
  { value: 'TAIHAO', label: 'TAIHAO' },
  { value: 'TEGRYS', label: 'TEGRYS' },
  { value: 'TERRA PLUS', label: 'TERRA PLUS' },
  { value: 'TG', label: 'TG' },
  { value: 'THREE-A', label: 'THREE-A' },
  { value: 'TRANSTONE', label: 'TRANSTONE' },
  { value: 'TRAX-MAX', label: 'TRAX-MAX' },
  { value: 'VARTA', label: 'VARTA' },
  { value: 'VREDESTEIN', label: 'VREDESTEIN' },
  { value: 'WANDA', label: 'WANDA' },
  { value: 'WATERFALL', label: 'WATERFALL' },
  { value: 'YOUKOHAMA', label: 'YOUKOHAMA' },
];

export default function ProductManufacturer({ onSetPage, manufacturer, onSetFilters }) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    onSetPage(0);
    onSetFilters((prev) => ({ ...prev, manufacturer: value }));
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: '300px',
      },
    },
  };

  return (
    <>
      <FormControl sx={{ m: 1, width: 250 }}>
        <InputLabel>Prodhuesi</InputLabel>
        <Select
          multiple
          value={manufacturer}
          onChange={handleChange}
          input={<OutlinedInput label="Prodhuesi" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {SORT_BY_OPTIONS.map((option) => (
            <MenuItem key={option?.value} value={option?.value}>
              <Checkbox checked={manufacturer.indexOf(option?.value) > -1} />
              <ListItemText primary={option?.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
