import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Card,
  Paper,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import './styleAllPages.css';

import toast from 'react-hot-toast';

import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { AlertDialogText } from '../components/alert';

import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
import { useAppContext } from '../context/app-context';
import { getComparator, applySortFilter } from '../utils/sortDataTable';

const TABLE_HEAD = [
  { id: 'firstName', label: 'Emri', alignRight: false },
  { id: 'lastName', label: 'Mbiemri', alignRight: false },
  { id: 'companyName', label: 'Kompania', alignRight: false },
  { id: 'email', label: 'Emaili', alignRight: false },
  { id: 'phone', label: 'Telefoni', alignRight: false },
  { id: 'role', label: 'Roli', alignRight: false },
  { id: '' },
];

export default function Usera() {
  const { authFetch } = useAppContext();

  const [isLoading, setIsLoading] = useState(true);

  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('firstName');
  const [filterName, setFilterName] = useState('');

  const [userId, setUserId] = useState('');
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [hasDeleted, setHasDeleted] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data } = await authFetch.get(`user?page=${page + 1}&search=${filterName}`);
        const { users, totalUsers } = data;
        setUsers(users);
        setTotalUsers(totalUsers);
        setHasDeleted(false);
        setIsLoading(false);
      } catch (error) {
        toast.error(error?.response?.data || error?.response?.data?.msg);
      }
    };
    fetchUsers();
  }, [page, filterName, hasDeleted]);

  const handleChangePage = async (e, newPage) => {
    setPage(newPage);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const filteredUsers = applySortFilter(users, getComparator(order, orderBy));

  const isUserNotFound = filteredUsers.length === 0;

  const handleDelete = (deleteId) => {
    setIsDeleteOpen(true);
    setUserId(deleteId);
  };
  const handleClose = () => {
    setIsDeleteOpen(false);
  };
  const handleConfirmDelete = async () => {
    try {
      const userDelete = await authFetch.delete(`user/${userId}`);

      setHasDeleted(true);
      setIsDeleteOpen(false);

      toast.success(userDelete?.data?.msg);
    } catch (error) {
      setIsDeleteOpen(false);
      toast.error(error?.response?.data || error?.response?.data?.msg);
    }
  };

  return (
    <>
      {isLoading ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <Helmet>
            <title> Përdoruesit </title>
          </Helmet>

          <Container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
              <Typography variant="h4" gutterBottom>
                Përdoruesit
              </Typography>
              <Button
                variant="contained"
                component={RouterLink}
                to="/dashboard/add-user"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                Shto përdorues
              </Button>
            </Stack>

            <Card>
              <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} position="leftPosition" />

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {filteredUsers.map((user) => {
                        const { _id, firstName, lastName, companyName, email, phone, role } = user;

                        return (
                          <TableRow hover key={_id} tabIndex={-1}>
                            <TableCell align="left">{firstName}</TableCell>
                            <TableCell align="left">{lastName}</TableCell>
                            <TableCell align="left">{companyName}</TableCell>
                            <TableCell align="left">{email}</TableCell>
                            <TableCell align="left">{phone}</TableCell>
                            <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
                              {role}
                            </TableCell>
                            <TableCell align="right">
                              <UserMoreMenu id={_id} handleDelete={handleDelete} />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant="h6" paragraph>
                                Nuk u gjet
                              </Typography>

                              <Typography variant="body2">
                                Nuk u gjetën rezultate për &nbsp;
                                <strong>&quot;{filterName}&quot;</strong>.
                                <br /> Provoni të përdorni fjalë tjera për të kërkuar
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={totalUsers}
                rowsPerPage={10}
                page={page}
                onPageChange={handleChangePage}
                showFirstButton
                showLastButton
              />
            </Card>

            {isDeleteOpen && (
              <AlertDialogText
                handleClose={handleClose}
                handleConfirm={handleConfirmDelete}
                text="A jeni i sigurt se dëshironi ta fshini?"
              />
            )}
          </Container>
        </>
      )}
    </>
  );
}
