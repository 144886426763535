import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import toast from 'react-hot-toast';
import jwtDecode from 'jwt-decode';

import {
  Container,
  Box,
  CircularProgress,
  Stack,
  Typography,
  OutlinedInput,
  InputAdornment,
  TablePagination,
  Card,
  Tooltip,
  IconButton,
  Button,
} from '@mui/material';

import {
  ProductSeasonFilter,
  ProductList,
  ProductCartWidget,
  ProductManufacturerFilter,
} from '../sections/@dashboard/products';

import { useAppContext } from '../context/app-context';
import Iconify from '../components/iconify';

export default function ProductsPage() {
  const { authFetch, user } = useAppContext();

  const [loading, isLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalProducts, setTotalProducts] = useState(0);
  const [filters, setFilters] = useState({ search: '', season: [], manufacturer: [] });
  const [showProducts, setShowProducts] = useState(
    !(filters?.season?.length === 0 && filters?.manufacturer?.length === 0)
  );

  const token = localStorage.getItem('token');
  const decodeToken = jwtDecode(token);

  const isAdmin = decodeToken?.role === 'admin';

  useEffect(() => {
    if (isAdmin || (!isAdmin && filters?.search?.length > 4)) {
      setShowProducts(true);
      getProducts();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, filters?.season, filters?.manufacturer]);

  const getProducts = async () => {
    isLoading(true);

    try {
      const { data } = await authFetch.get(
        `products?page=${page + 1}&limit=${limit}&search=${filters?.search}&season=${filters?.season}&manufacturer=${
          filters?.manufacturer
        }`
      );

      setProducts(data?.products);

      setTotalProducts(data?.totalProducts);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data || error?.response?.data?.msg);
    }

    isLoading(false);
  };

  const handleChangePage = async (e, newPage) => {
    setPage(newPage);
  };

  const handleSearch = (e) => {
    setShowProducts(false);

    setPage(0);

    setFilters((prev) => ({ ...prev, search: e?.target?.value }));
  };

  const handleSearchClick = () => {
    if (isAdmin) {
      getProducts();
    } else if (!isAdmin && filters?.search?.length > 4) {
      setShowProducts(true);
      getProducts();
    } else {
      toast.error('Ju lutem shënoni 5 e më shumë shifra.');
    }
  };

  const handleLimitPage = async (e) => {
    setPage(0);
    setLimit(e?.target?.value);
  };

  return (
    <>
      <Helmet>
        <title> Produktet </title>
      </Helmet>

      <Container className="ProductPage">
        <Typography variant="h4" sx={{ mb: 3 }}>
          Produktet
        </Typography>

        <Card>
          <Stack
            direction={{ sm: 'column', md: 'row' }}
            alignItems="center"
            justifyContent="space-between"
            sx={{ m: 3 }}
          >
            <Stack direction="row">
              <OutlinedInput
                value={filters.search}
                onChange={(e) => handleSearch(e)}
                placeholder="Dimensionet..."
                sx={{ width: 250, marginRight: '8px' }}
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </InputAdornment>
                }
              />
              <Button variant="outlined" sx={{ height: '56px' }} onClick={handleSearchClick}>
                Kërko
              </Button>
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center">
              <ProductSeasonFilter
                onSetPage={setPage}
                seasons={filters?.season}
                filters={filters}
                onSetFilters={setFilters}
                setShowProducts={setShowProducts}
                isAdmin={isAdmin}
              />

              {isAdmin && (
                <ProductManufacturerFilter
                  onSetPage={setPage}
                  manufacturer={filters?.manufacturer}
                  onSetFilters={setFilters}
                />
              )}

              <Tooltip title="Pastro filterat" placement="top">
                <IconButton
                  onClick={() => {
                    setShowProducts(false);
                    setFilters({ search: '', season: [], manufacturer: [] });
                  }}
                >
                  <Iconify icon="eva:close-outline" />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            (user?.role === 'admin' || showProducts) && (
              <>
                <ProductList products={products} />

                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  component="div"
                  count={totalProducts}
                  rowsPerPage={limit}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleLimitPage}
                  labelRowsPerPage=""
                  showFirstButton
                  showLastButton
                />
              </>
            )
          )}
          <ProductCartWidget />
        </Card>
      </Container>
    </>
  );
}
