import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';

import imageCompression from 'browser-image-compression';

import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  FormHelperText,
  Button,
} from '@mui/material';
import { useAppContext } from '../../../context/app-context';
import Iconify from '../../../components/iconify';
import { convertBase64 } from '../../../utils/helpers';

export default function RegisterForm() {
  const navigate = useNavigate();
  const { authFetch } = useAppContext();
  const rolesArray = ['user', 'admin'];

  const [showPassword, setShowPassword] = useState(false);
  const [profileImage, setProfileImage] = useState('');

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().min(3, 'Shumë i shkurtër!').max(50, 'Shumë i gjatë!').required('Nevojitet emri'),
    lastName: Yup.string().min(3, 'Shumë i shkurtër!').max(50, 'Shumë i gjatë!').required('Nevojitet mbiemri'),
    companyName: Yup.string().min(3, 'Shumë i shkurtër!').max(50, 'Shumë i gjatë!').required('Nevojitet kompania'),
    role: Yup.string().required('Nevojitet roli'),
    email: Yup.string().email('Emaili duhet të jetë valid').required('Nevojitet emaili'),
    password: Yup.string().required('Nevojitet fjalëkalimi'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      companyName: '',
      role: '',
      email: '',
      password: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      (async () => {
        try {
          const newUser = {
            firstName: formik.values.firstName,
            lastName: formik.values.lastName,
            companyName: formik.values.companyName,
            role: formik.values.role,
            email: formik.values.email,
            password: formik.values.password,
            profileImg: profileImage,
          };
          const createdUser = await authFetch.post('user', newUser);

          if (createdUser !== undefined) {
            setTimeout(() => {
              navigate('/dashboard/user', { replace: true });
            }, 3000);
            toast.success('Përdoruesi është shtuar me sukses');
          }
        } catch (error) {
          console.log(error);
          toast.error(error?.response?.data?.msg);
        }
      })();
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const allowedMimetype = ['image/png', 'image/jpeg'];

  const handleProfileImage = async (e) => {
    try {
      const image = e?.target?.files[0];

      if (image?.size > 1 * 1024 * 1024) {
        toast.error('Madhësia duhet të jetë më e vogel se 1MB.');
        return;
      }

      if (!allowedMimetype.includes(image.type)) {
        toast.error('Formati i lejuar është: .png, .jpg dhe .jpeg');
        return;
      }

      const options = { maxSizeMB: 0.5 };

      const compressedFile = await imageCompression(image, options);

      const profileImg = await convertBase64(compressedFile);

      setProfileImage(profileImg);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                label="Emri"
                {...getFieldProps('firstName')}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />

              <TextField
                fullWidth
                label="Mbiemri"
                {...getFieldProps('lastName')}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />
            </Stack>
            <TextField
              fullWidth
              label="Kompania"
              {...getFieldProps('companyName')}
              error={Boolean(touched.companyName && errors.companyName)}
              helperText={touched.companyName && errors.companyName}
            />

            <FormControl fullWidth error={Boolean(touched.role && errors.role)}>
              <InputLabel id="role-label">Roli</InputLabel>
              <Select id="select-role" label="Roli" {...getFieldProps('role')} sx={{ textTransform: 'capitalize' }}>
                {rolesArray.map((role, index) => (
                  <MenuItem key={index} value={role} sx={{ textTransform: 'capitalize' }}>
                    {role}
                  </MenuItem>
                ))}
              </Select>
              {Boolean(touched.role && errors.role) && <FormHelperText>{errors.role}</FormHelperText>}
            </FormControl>

            <TextField
              fullWidth
              autoComplete="email"
              type="email"
              label="Emaili"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Fjalëkalimi"
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />

            <Stack direction="column" alignItems="flex-start" spacing={2}>
              <Button
                variant="outlined"
                component="label"
                sx={{ width: '200px', height: '50px', borderStyle: 'dashed' }}
              >
                Ngarko logo
                <input hidden accept="image/*" multiple type="file" onChange={(e) => handleProfileImage(e)} />
              </Button>
              {profileImage && (
                <img
                  className="ProfileImage"
                  src={profileImage}
                  alt="Profile"
                  width={'200px'}
                  style={{ borderRadius: '6px' }}
                />
              )}
            </Stack>

            <LoadingButton fullWidth size="large" type="submit" variant="contained">
              Shto
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
}
