export const LOGIN_USER_BEGIN = 'LOGIN_USER_BEGIN';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';

export const LOGOUT_USER = 'LOGOUT_USER';

export const UPDATE_USER_BEGIN = 'UPDATE_USER_BEGIN';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const INCREASE_PRODUCT_QTY = 'INCREASE_PRODUCT_QTY';
export const DECREASE_PRODUCT_QTY = 'DECREASE_PRODUCT_QTY';

export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';

export const UPDATE_USER_STATE = 'UPDATE_USER_STATE';
