import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import toast from 'react-hot-toast';

import {
  Container,
  Box,
  CircularProgress,
  Stack,
  Typography,
  OutlinedInput,
  TablePagination,
  Card,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
} from '@mui/material';
import jwtDecode from 'jwt-decode';
import { OrderMoreMenu } from '../sections/@dashboard/order';

import { useAppContext } from '../context/app-context';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { fCurrency } from '../utils/formatNumber';

const statusTypes = [
  { value: 'pending', label: 'Në pritje' },
  { value: 'confirmed', label: 'Konfirmuar' },
  { value: 'rejected', label: 'Refuzuar' },
  { value: 'cancelled', label: 'Anuluar' },
];
export default function OrdersPage() {
  const { authFetch, user } = useAppContext();

  const [loading, isLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [filters, setFilters] = useState({ userId: '', status: '' });

  const decodeToken = jwtDecode(localStorage.getItem('token'));

  const isAdmin = decodeToken?.role === 'admin';

  useEffect(() => {
    getOrders();
  }, [page, filters?.userId, filters?.status]);

  useEffect(() => {
    if (isAdmin) getUsers();
  }, []);

  const getOrders = async () => {
    isLoading(true);

    try {
      const { data } = await authFetch.get(
        `order?page=${page + 1}&userId=${filters?.userId}&status=${filters?.status}`
      );

      setOrders(data?.orders);

      setTotalOrders(data?.totalOrders);
    } catch (error) {
      toast.error('Ndodhi një gabim! Provo përsëri.');
      console.log(error);
    }

    isLoading(false);
  };

  const getUsers = async () => {
    isLoading(true);

    try {
      const { data } = await authFetch.get('user');

      setUsers(data?.users);
    } catch (error) {
      toast.error('Ndodhi një gabim! Provo përsëri.');
      console.log(error);
    }

    isLoading(false);
  };

  const handleUserSelect = (e) => {
    setPage(0);
    setFilters((prev) => ({ ...prev, userId: e?.target?.value }));
  };

  const handleStatusSelect = (e) => {
    setPage(0);
    setFilters((prev) => ({ ...prev, status: e?.target?.value }));
  };

  const handleChangePage = async (e, newPage) => {
    setPage(newPage);
  };

  const TABLE_HEAD = [
    { id: 'companyName', label: 'Kompania', alignRight: false },
    { id: 'totalProducts', label: 'Nr. produkteve', alignRight: false },
    { id: 'total', label: 'Totali', alignRight: false },
    { id: 'status', label: 'Statusi', alignRight: false },
    { id: 'date', label: 'Data', alignRight: false },
    { id: '' },
  ];

  const handleStatusChip = (status) => {
    switch (status) {
      case 'pending':
        return <Chip color="default" label="Në pritje" sx={{ width: '100px' }} />;

      case 'confirmed':
        return <Chip color="success" label="Konfirmuar" sx={{ width: '100px' }} />;

      case 'rejected':
        return <Chip color="error" label="Refuzuar" sx={{ width: '100px' }} />;

      case 'cancelled':
        return <Chip color="error" label="Anuluar" sx={{ width: '100px' }} />;

      default:
        return <Chip color="default" label={status} sx={{ width: '100px' }} />;
    }
  };

  const handleConfirm = async (id) => {
    try {
      const { status } = await authFetch.patch(`order/${id}/confirm`);

      if (status === 200) {
        getOrders();
        toast.success('U konfirmua me sukses.');
      }
    } catch (error) {
      console.log(error);
      toast.error('Ndodhi një gabim! Provo përsëri.');
    }
  };

  const handleReject = async (id) => {
    try {
      const { status } = await authFetch.patch(`order/${id}/reject`);

      if (status === 200) {
        getOrders();
        toast.success('U refuzua me sukses.');
      }
    } catch (error) {
      console.log(error);
      toast.error('Ndodhi një gabim! Provo përsëri.');
    }
  };

  const handleCancel = async (id) => {
    try {
      const { status, data } = await authFetch.patch(`order/${id}/cancel`, { userId: user?._id });

      if (status === 200) {
        getOrders();
        toast.success(data?.msg);
      }
    } catch (error) {
      console.log(error);
      toast.error('Ndodhi një gabim! Provo përsëri.');
    }
  };

  const handleDelete = async (id) => {
    try {
      const { status } = await authFetch.delete(`order/${id}`);

      if (status === 200) {
        getOrders();
        toast.success('U fshij me sukses.');
      }
    } catch (error) {
      console.log(error);
      toast.error('Ndodhi një gabim! Provo përsëri.');
    }
  };

  return (
    <>
      <Helmet>
        <title> Porositë </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 3 }}>
          Porositë
        </Typography>

        <Card>
          <Stack direction="row" alignItems="center" gap={2} sx={{ m: 3 }}>
            {isAdmin && (
              <FormControl>
                <InputLabel>Kompania</InputLabel>
                <Select
                  value={filters?.userId}
                  onChange={handleUserSelect}
                  input={<OutlinedInput label="Kompania" />}
                  sx={{ width: '250px' }}
                >
                  {users?.map((user) => {
                    const { _id, companyName, firstName, lastName } = user;

                    return (
                      <MenuItem key={_id} value={_id}>
                        {companyName || `${firstName} ${lastName}`}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}

            <FormControl>
              <InputLabel>Statusi</InputLabel>
              <Select
                value={filters?.status}
                onChange={handleStatusSelect}
                input={<OutlinedInput label="Statusi" />}
                sx={{ width: '250px' }}
              >
                {statusTypes?.map((status, i) => {
                  const { value, label } = status || {};

                  return (
                    <MenuItem key={i} value={value}>
                      {label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <Tooltip title="Pastro filterat" placement="top">
              <IconButton onClick={() => setFilters({ userId: '', status: '' })}>
                <Iconify icon="eva:close-outline" />
              </IconButton>
            </Tooltip>
          </Stack>
          {loading ? (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>
            </>
          ) : (
            <>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {TABLE_HEAD.map((headCell) => (
                          <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                            {headCell.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {orders.map((order) => {
                        const { _id, user, status, total, products, createdAt } = order || {};

                        return (
                          <TableRow hover key={_id} tabIndex={-1}>
                            <TableCell align="left">
                              {user?.companyName || `${user?.firstName} ${user?.lastName}` || 'Pa emer'}
                            </TableCell>
                            <TableCell align="left">{products?.length} </TableCell>
                            <TableCell align="left">{fCurrency(total)} </TableCell>
                            <TableCell align="left">{handleStatusChip(status)} </TableCell>
                            <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
                              {moment(createdAt).format('DD.MM.YYYY · HH:mm')}
                            </TableCell>
                            <TableCell align="right">
                              <OrderMoreMenu
                                id={_id}
                                order={order}
                                getOrders={getOrders}
                                onHandleConfirm={handleConfirm}
                                onHandleReject={handleReject}
                                onHandleCancel={handleCancel}
                                onHandleDelete={handleDelete}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>

                    {orders.length === 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper sx={{ textAlign: 'center' }}>
                              <Typography variant="h6" paragraph>
                                Nuk u gjet
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={totalOrders}
                rowsPerPage={10}
                page={page}
                onPageChange={handleChangePage}
                showFirstButton
                showLastButton
              />
            </>
          )}
        </Card>
      </Container>
    </>
  );
}
