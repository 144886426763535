import { Navigate, useRoutes } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';

import { Page404, LoginPage, ProductsPage, UserPage, ProfilePage, AddUserPage, OrdersPage } from './pages';

export default function Router() {
  let isAdmin = false;

  const token = localStorage.getItem('token');

  if (token) {
    const decodeToken = jwtDecode(token);

    isAdmin = decodeToken?.role === 'admin';
  }

  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/orders" />, index: true },
        { path: 'products', element: <ProductsPage /> },
        { path: 'orders', element: <OrdersPage /> },
        { path: 'profile', element: <ProfilePage /> },
        { path: 'user', element: isAdmin ? <UserPage /> : <Navigate to="/dashboard/products" /> },
        { path: 'add-user', element: isAdmin ? <AddUserPage /> : <Navigate to="/dashboard/products" /> },
      ],
    },

    {
      path: '/',
      element: <SimpleLayout />,
      children: [
        { path: '/', element: <Navigate to="/login" /> },
        { path: 'login', element: <LoginPage /> },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);

  return routes;
}
