import {
  LOGIN_USER_BEGIN,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGOUT_USER,
  UPDATE_USER_BEGIN,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  INCREASE_PRODUCT_QTY,
  DECREASE_PRODUCT_QTY,
  CREATE_ORDER_SUCCESS,
  UPDATE_USER_STATE,
} from './actions';

import { initialState } from './app-context';

const reducer = (state, action) => {
  if (action.type === LOGIN_USER_BEGIN)
    return {
      ...state,
      isLoading: true,
    };

  if (action.type === LOGIN_USER_SUCCESS)
    return {
      ...state,
      isLoading: false,
      user: action.payload.user,
      token: action.payload.token,
    };

  if (action.type === LOGIN_USER_ERROR)
    return {
      ...state,
      isLoading: false,
    };

  if (action.type === LOGOUT_USER)
    return {
      ...initialState,
      user: null,
      token: null,
    };

  if (action.type === UPDATE_USER_BEGIN)
    return {
      ...state,
      isLoading: true,
    };

  if (action.type === UPDATE_USER_SUCCESS)
    return {
      ...state,
      isLoading: false,
      user: action.payload.user,
      token: action.payload.token,
    };

  if (action.type === UPDATE_USER_STATE)
    return {
      ...state,
      user: action.payload.user,
    };

  if (action.type === UPDATE_USER_ERROR)
    return {
      ...state,
      isLoading: false,
    };

  if (
    action.type === ADD_TO_CART ||
    action.type === REMOVE_FROM_CART ||
    action.type === INCREASE_PRODUCT_QTY ||
    action.type === DECREASE_PRODUCT_QTY
  )
    return {
      ...state,
      order: action.payload.order,
    };

  if (action.type === CREATE_ORDER_SUCCESS)
    return {
      ...state,
      order: action.payload.order,
    };

  throw new Error(`Asnjë veprim i tillë: ${action.type}`);
};

export default reducer;
