import { MenuItem, Select, ListItemText, OutlinedInput, FormControl, InputLabel, Checkbox } from '@mui/material';

const SORT_BY_OPTIONS = [
  { value: 'V', label: 'Verë' },
  { value: 'D', label: 'Dimër' },
  { value: 'VD', label: 'Verë - Dimër' },
  { value: 'A', label: 'All seasons' },
];

export default function ProductSeason({ onSetPage, seasons, onSetFilters, setShowProducts }) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    onSetPage(0);

    onSetFilters((prev) => ({ ...prev, season: value }));
  };

  return (
    <>
      <FormControl sx={{ m: 1, width: 250 }}>
        <InputLabel>Sezona</InputLabel>
        <Select
          multiple
          value={seasons}
          onChange={handleChange}
          input={<OutlinedInput label="Sezona" />}
          renderValue={(selected) => selected.join(', ')}
        >
          {SORT_BY_OPTIONS.map((option) => (
            <MenuItem key={option?.value} value={option?.value}>
              <Checkbox checked={seasons.indexOf(option?.value) > -1} />
              <ListItemText primary={option?.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
