import toast from 'react-hot-toast';

import {
  Box,
  Stack,
  Button,
  Drawer,
  Divider,
  IconButton,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
} from '@mui/material';

import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import { convertToNumber, fCurrency } from '../../../utils/formatNumber';
import { useAppContext } from '../../../context/app-context';

export default function ProductCartSidebar({ openFilter, onCloseFilter }) {
  const { order, removeFromCart, increaseProductQty, decreaseProductQty, createOrder } = useAppContext();

  const TABLE_HEAD = [
    { id: 'companyName', label: `Produkti`, alignRight: false },
    { id: 'totalProducts', label: `Çmimi`, alignRight: false },
    { id: 'total', label: `Sasia`, alignRight: false },
    { id: 'status', label: `Totali`, alignRight: false },
    { id: '' },
  ];

  let total = 0;

  return (
    <>
      <Drawer
        anchor="right"
        open={openFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: '50% ', border: 'none', overflow: 'hidden' },
        }}
        className="OrderModal"
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Porosia
          </Typography>
          <IconButton onClick={onCloseFilter}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          {order.length > 0 ? (
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {order?.map((or) => {
                    const { _id, productId, manufacturer, model, price, dimension, quantity, qty } = or;

                    const subtotal = fCurrency(Number(fCurrency(price, true)) * Number(qty));

                    total += convertToNumber(subtotal);

                    return (
                      <TableRow hover key={_id} tabIndex={-1}>
                        <TableCell align="left">
                          {`${manufacturer} ${model}`} <br /> <small>{dimension} </small>
                        </TableCell>
                        <TableCell align="left">{fCurrency(price)}</TableCell>
                        <TableCell align="left">
                          <div className="PlusMinusButtons">
                            <button
                              disabled={qty === 1 && true}
                              onClick={() => decreaseProductQty(productId, quantity)}
                            >
                              -
                            </button>
                            <span>{qty}</span>
                            <button onClick={() => increaseProductQty(productId, quantity)}>+</button>
                          </div>
                        </TableCell>
                        <TableCell align="left">{subtotal}</TableCell>
                        <TableCell align="center">
                          <IconButton onClick={() => removeFromCart(productId)} aria-label="delete">
                            <Iconify icon="eva:trash-2-outline" sx={{ width: 20, height: 20 }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}

                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell style={{ fontWeight: 'bold' }}>Totali:</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>{fCurrency(total)}</TableCell>
                    <TableCell />
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="calc(100vh - 165px)">
              <span>Asnjë produkt në shportë.</span>
            </Box>
          )}
        </Scrollbar>

        <Box sx={{ p: 3 }} display="flex" justifyContent="center" alignItems="center">
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            startIcon={<Iconify icon="eva:shopping-cart-outline" />}
            onClick={() => {
              createOrder();
            }}
          >
            Porosit
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
