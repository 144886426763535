import { Helmet } from 'react-helmet-async';
import React from 'react';
import { Typography, Container, Card } from '@mui/material';

import { RegisterForm } from '../sections/@dashboard/user';

export default function AddUserPage() {
  return (
    <>
      <Helmet>
        <title>Shto përdorues</title>
      </Helmet>

      <Container component="main" maxWidth="xs">
        <div>
          <Typography variant="h4" component="div" sx={{ pb: 3 }}>
            Shto përdorues
          </Typography>

          <Card sx={{ p: 3 }}>
            <RegisterForm />
          </Card>
        </div>
      </Container>
    </>
  );
}
