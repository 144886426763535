import Iconify from '../../../components/iconify';

export const adminNav = [
  {
    title: `Produktet`,
    path: '/dashboard/products',
    icon: <Iconify icon="eva:shopping-bag-outline" />,
  },
  {
    title: `Porositë`,
    path: '/dashboard/orders',
    icon: <Iconify icon="eva:grid-outline" />,
  },
  {
    title: `Përdoruesit`,
    path: '/dashboard/user',
    icon: <Iconify icon="eva:people-outline" />,
  },
  {
    title: `Profili`,
    path: '/dashboard/profile',
    icon: <Iconify icon="eva:person-outline" />,
  },
];

export const userNav = [
  {
    title: `Produktet`,
    path: '/dashboard/products',
    icon: <Iconify icon="eva:shopping-bag-outline" />,
  },
  {
    title: `Porositë`,
    path: '/dashboard/orders',
    icon: <Iconify icon="eva:grid-outline" />,
  },
  {
    title: `Profili`,
    path: '/dashboard/profile',
    icon: <Iconify icon="eva:person-outline" />,
  },
];
