import { createContext, useReducer, useContext } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';

import reducer from './reducer';

import {
  LOGIN_USER_SUCCESS,
  LOGIN_USER_BEGIN,
  LOGOUT_USER,
  UPDATE_USER_BEGIN,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  INCREASE_PRODUCT_QTY,
  DECREASE_PRODUCT_QTY,
  CREATE_ORDER_SUCCESS,
  UPDATE_USER_STATE,
} from './actions';
import { fCurrency, convertToNumber } from '../utils/formatNumber';

const token = localStorage.getItem('token');
const user = localStorage.getItem('user');

const initialState = {
  isLoading: false,
  user: user ? JSON.parse(user) : null,
  token,
  order: [],
};

const urlLogin = 'https://tire-api.herokuapp.com/api/v1/auth/login';

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const authFetch = axios.create({
    baseURL: 'https://tire-api.herokuapp.com/api/v1',
  });
  authFetch.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${state.token}`;
      return config;
    },
    (error) => Promise.reject(error)
  );
  authFetch.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 401) logOut();

      return Promise.reject(error);
    }
  );

  const addUserToLocalStorage = ({ user, token }) => {
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', token);
  };

  const removeUserToLocalStorage = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
  };

  const loginUser = async (currentUser) => {
    dispatch({ type: LOGIN_USER_BEGIN });
    try {
      const { data } = await axios.post(urlLogin, currentUser);
      const { user, token } = data;

      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: { user, token },
      });

      toast.success('Kyçja me sukses!');

      addUserToLocalStorage({ user, token });
    } catch (error) {
      toast.error('Kredincialet janë jo-valide.');
    }
  };

  const logOut = () => {
    dispatch({ type: LOGOUT_USER });
    removeUserToLocalStorage();
  };

  const updateUser = async (userId, editedUser) => {
    dispatch({ type: UPDATE_USER_BEGIN });

    try {
      const { data } = await authFetch.patch(`user/${userId}`, editedUser);
      let { user, token } = state;

      if (user._id === userId) {
        ({ user, token } = data);
      }

      dispatch({ type: UPDATE_USER_SUCCESS, payload: { user, token } });

      addUserToLocalStorage({ user, token });
    } catch (error) {
      if (error.response.status !== 401) {
        dispatch({ type: UPDATE_USER_ERROR, payload: { msg: error?.response?.data?.msg } });
      }
    }
  };

  const updateUserState = (user) => {
    try {
      const { token } = state;

      dispatch({ type: UPDATE_USER_STATE, payload: { user } });

      addUserToLocalStorage({ user, token });
    } catch (error) {
      console.log(error);
    }
  };

  const addToCart = async (product) => {
    try {
      const order = state?.order;

      const foundObject = order.find((obj) => obj?.productId === product?.productId);

      if (product?.qty < 1) {
        toast.error('Sasia duhet të jetë më e madhe se një');
        return;
      }

      if (product?.qty > product?.quantity || foundObject?.qty + product?.qty > product?.quantity) {
        toast.error('Sasia e produktit duhet të jetë më e vogël');
        return;
      }

      if (foundObject) {
        foundObject.qty += product?.qty;
      } else {
        order.push(product);
      }

      dispatch({ type: ADD_TO_CART, payload: { order } });

      toast.success('Produkti u shtua');
    } catch (error) {
      toast.error('Ndodhi një gabim!');
      console.log(error);
    }
  };

  const removeFromCart = async (productId) => {
    try {
      let order = state?.order;

      order = order?.filter((obj) => obj?.productId !== productId);

      dispatch({ type: REMOVE_FROM_CART, payload: { order } });

      toast.success('Produkti është hequr');
    } catch (error) {
      toast.error('Ndodhi një gabim!');
      console.log(error);
    }
  };

  const increaseProductQty = async (productId, quantity) => {
    try {
      const order = state?.order;

      const foundObject = order.find((obj) => obj?.productId === productId);

      if (foundObject.qty + 1 > quantity) {
        toast.error('Ju keni arritur sasinë maksimale');
        return;
      }

      if (foundObject) {
        foundObject.qty += 1;

        dispatch({ type: INCREASE_PRODUCT_QTY, payload: { order } });

        toast.success('Sasia e produktit është rritur për një');
      }
    } catch (error) {
      toast.error('Ndodhi një gabim!');
      console.log(error);
    }
  };

  const decreaseProductQty = async (productId, quantity) => {
    try {
      const order = state?.order;

      const foundObject = order.find((obj) => obj?.productId === productId);

      if (foundObject && foundObject?.qty > quantity) {
        toast.error('Ju keni arritur sasinë maksimale');

        foundObject.qty = quantity;

        dispatch({ type: DECREASE_PRODUCT_QTY, payload: { order } });
      } else if (foundObject && foundObject?.qty > 1) {
        foundObject.qty -= 1;

        toast.success('Sasia e produktit është zvogluar për një');

        dispatch({ type: DECREASE_PRODUCT_QTY, payload: { order } });
      } else {
        toast.error('Ndodhi një gabim!');
      }
    } catch (error) {
      toast.error('Ndodhi një gabim!');
      console.log(error);
    }
  };

  const createOrder = async () => {
    dispatch({ type: UPDATE_USER_BEGIN });
    try {
      const userId = state?.user?._id;
      let order = state?.order;
      let total = 0;

      order = order.map((item) => {
        const { _id, price, qty } = item;

        let subtotal = fCurrency(fCurrency(price, true) * qty, true);

        subtotal = convertToNumber(subtotal);

        total += subtotal;

        return {
          productId: _id,
          quantity: qty,
          subtotal,
        };
      });

      const params = { userId, products: order, total };

      const { status } = await authFetch.post(`order`, params);

      if (status === 201) {
        dispatch({ type: CREATE_ORDER_SUCCESS, payload: { order: [] } });

        toast.success('Porosia u dërgua me sukses.');
      }
    } catch (error) {
      if (error.response.status !== 401) {
        console.log(error);
        toast.error(error?.response?.data?.msg);
      }
    }
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        loginUser,
        logOut,
        updateUser,
        authFetch,
        addToCart,
        removeFromCart,
        increaseProductQty,
        decreaseProductQty,
        createOrder,
        updateUserState,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => useContext(AppContext);

export { initialState, AppProvider, useAppContext };
