import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Badge } from '@mui/material';

import { useAppContext } from '../../../context/app-context';
import Iconify from '../../../components/iconify';
import ProductCartSidebar from './ProductCartSidebar';

const StyledRoot = styled('div')(({ theme }) => ({
  zIndex: 999,
  right: 0,
  display: 'flex',
  cursor: 'pointer',
  position: 'fixed',
  alignItems: 'center',
  top: theme.spacing(16),
  height: theme.spacing(5),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1.25),
  boxShadow: theme.customShadows.z20,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  borderTopLeftRadius: Number(theme.shape.borderRadius) * 2,
  borderBottomLeftRadius: Number(theme.shape.borderRadius) * 2,
  transition: theme.transitions.create('opacity'),
  '&:hover': { opacity: 0.72 },
}));

export default function CartWidget() {
  const { order } = useAppContext();

  const [openFilter, setOpenFilter] = useState(false);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  return (
    <StyledRoot>
      <Badge showZero badgeContent={order?.length} color="error" max={99} onClick={handleOpenFilter}>
        <Iconify icon="eva:shopping-cart-fill" width={24} height={24} />
      </Badge>
      <ProductCartSidebar openFilter={openFilter} onCloseFilter={handleCloseFilter} />
    </StyledRoot>
  );
}
