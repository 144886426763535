import { Toaster } from 'react-hot-toast';

import Router from './routes';
import ThemeProvider from './theme';
import { AppProvider } from './context/app-context';

import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';

export default function App() {
  return (
    <AppProvider>
      <ThemeProvider>
        <ScrollToTop />
        <StyledChart />
        <Toaster position="bottom-right" />
        <Router />
      </ThemeProvider>
    </AppProvider>
  );
}
