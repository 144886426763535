import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Box, Stack, CircularProgress, Typography, Button, Grid, Card, Container } from '@mui/material';
import imageCompression from 'browser-image-compression';

import { ProfileForm, ProfilePasswordForm } from '../sections/@dashboard/user';
import { useAppContext } from '../context/app-context';
import { convertBase64 } from '../utils/helpers';

export default function ProfilePage() {
  const { state } = useLocation();
  const { authFetch, user, updateUserState } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  const [currentUser, setCurrentUser] = useState(null);
  const [newProfileImage, setNewProfileImage] = useState('');

  useEffect(() => {
    fetchUser();
  }, [state]);

  const fetchUser = async () => {
    try {
      const { data } = await authFetch.get(`user/${state?.userId || user._id}`);

      setCurrentUser(data);

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.msg);
    }
  };

  const allowedMimetype = ['image/png', 'image/jpeg'];

  const handleProfileImage = async (e) => {
    try {
      const image = e?.target?.files[0];

      if (image?.size > 1 * 1024 * 1024) {
        toast.error('Madhësia duhet të jetë më e vogel se 1MB.');
        return;
      }

      if (!allowedMimetype.includes(image.type)) {
        toast.error('Formati i lejuar është: .png, .jpg dhe .jpeg');
        return;
      }

      const options = { maxSizeMB: 0.5 };

      const compressedFile = await imageCompression(image, options);

      const profileImg = await convertBase64(compressedFile);

      setNewProfileImage(profileImg);

      const { data, status } = await authFetch.patch(`user/${currentUser?._id}/upload-image`, { profileImg });

      if (status === 200) {
        toast.success(data?.msg);

        setCurrentUser(data?.user);

        setNewProfileImage('');

        if (data?.user?._id === user?._id) updateUserState(data?.user);
      }
    } catch (error) {
      setNewProfileImage('');
      console.log(error);
      toast.error(error?.response?.data?.msg);
    }
  };

  return (
    <>
      {isLoading ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <Helmet>
            <title> Profili</title>
          </Helmet>
          <Container>
            <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
              <Grid item xs={12} md={4}>
                <Card sx={{ width: '100%', py: 3 }}>
                  <Box>
                    <img
                      style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                      className="ProfileImage"
                      src={newProfileImage || currentUser?.profileImg}
                      alt="Profile"
                      width={'200px'}
                    />
                  </Box>
                  <Stack direction="column" alignItems="center" spacing={2} sx={{ mt: 2 }}>
                    <Button
                      variant="outlined"
                      component="label"
                      sx={{ width: '200px', height: '50px', borderStyle: 'dashed' }}
                    >
                      Ngarko
                      <input hidden accept="image/*" multiple type="file" onChange={(e) => handleProfileImage(e)} />
                    </Button>
                  </Stack>
                </Card>
              </Grid>
              <Grid item xs={12} md={8} alignItems="center">
                <Card sx={{ p: 3 }}>
                  <Typography variant="h4" sx={{ mb: 4 }} align="center">
                    Përditëso profilin
                  </Typography>
                  <ProfileForm userId={state?.userId || user._id} />
                </Card>

                <Card sx={{ mt: 8, p: 3 }}>
                  <Typography variant="h4" sx={{ mb: 4 }} align="center">
                    Ndrysho fjalëkalimin
                  </Typography>
                  <ProfilePasswordForm userId={state?.userId || user._id} />
                </Card>
              </Grid>
            </Grid>
          </Container>
        </>
      )}
    </>
  );
}
