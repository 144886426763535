import * as Yup from 'yup';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';

import Iconify from '../../../components/iconify';
import { useAppContext } from '../../../context/app-context';

export default function ProfilePasswordForm({ userId }) {
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);

  const { authFetch, user } = useAppContext();

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleNewShowPassword = () => {
    setShowNewPassword((show) => !show);
  };

  const handleShowConfirmedPassword = () => {
    setShowConfirmedPassword((show) => !show);
  };

  const ProfilePasswordSchema = Yup.object().shape({
    newPassword: Yup.string().required('Nevojitet fjalëkalimi i ri'),
    confirmedPassword: Yup.string().when('newPassword', {
      is: (val) => val && val.length > 0,
      then: Yup.string().oneOf([Yup.ref('newPassword')], 'Konfirmo fjalëkalimin'),
    }),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      newPassword: '',
      confirmedPassword: '',
    },
    validationSchema: ProfilePasswordSchema,
    onSubmit: (values, { setSubmitting }) => {
      (async () => {
        try {
          const changePassord = {
            currentPassword: formik.values.password,
            newPassword: formik.values.newPassword,
            confirmPassword: formik.values.confirmedPassword,
          };
          const changedPassord = await authFetch.patch(`user/${userId}/change-password`, changePassord);
          if (changedPassord !== undefined) toast.success('Fjalëkalimi është ndryshuar me sukses');
        } catch (error) {
          toast.error(error?.response?.data?.msg);
        }
      })();
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {user?.role !== 'admin' && (
              <TextField
                fullWidth
                type={showPassword ? 'text' : 'password'}
                label={'Fjalëkalimi aktual'}
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
            )}

            <TextField
              fullWidth
              type={showNewPassword ? 'text' : 'password'}
              label={'Fjalëkalimi i ri'}
              {...getFieldProps('newPassword')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleNewShowPassword} edge="end">
                      <Iconify icon={showNewPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.newPassword && errors.newPassword)}
              helperText={touched.newPassword && errors.newPassword}
            />

            <TextField
              fullWidth
              type={showConfirmedPassword ? 'text' : 'password'}
              label={'Konfirmo fjalëkalimin e ri'}
              {...getFieldProps('confirmedPassword')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowConfirmedPassword} edge="end">
                      <Iconify icon={showConfirmedPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.confirmedPassword && errors.confirmedPassword)}
              helperText={touched.confirmedPassword && errors.confirmedPassword}
            />

            <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting}>
              Përditëso fjalëkalimin
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
}
