import { fCurrency, convertToNumber } from './formatNumber';

//

export const calculateSubtotal = (price, quantity) => {
  let subtotal = fCurrency(fCurrency(price, true) * quantity, true);

  subtotal = convertToNumber(subtotal);

  return subtotal;
};

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
