import { useState } from 'react';
import toast from 'react-hot-toast';

import {
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Stack,
  Typography,
} from '@mui/material';

import { useAppContext } from '../../../context/app-context';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import { fCurrency } from '../../../utils/formatNumber';
import { calculateSubtotal } from '../../../utils/helpers';

const TABLE_HEAD = [
  { id: 'manufacturerAndModel', label: 'Prodhuesi dhe modeli', alignRight: false },
  { id: 'dimensions', label: 'Dimensionet', alignRight: false },
  { id: 'price', label: 'Çmimi', alignRight: false },
  { id: 'quantity', label: 'Sasia', alignRight: false },
  { id: 'subtotal', label: 'Totali', alignRight: false },
];

export default function OrderDetails({
  openModal,
  onHandleClose,
  order,
  getOrders,
  onHandleConfirm,
  onHandleReject,
  isAdmin,
  isConfirmed,
  isRejected,
  isCancelled,
}) {
  const { authFetch } = useAppContext();
  const [isEdit, setIsEdit] = useState(false);
  const [updateOrder, setUpdateOrder] = useState(order);
  const [isUpdated, setIsUpdated] = useState(false);

  const handleRemoveProduct = (productId) => {
    let products = updateOrder?.products;

    products = products?.filter((product) => product?._id !== productId);

    setUpdateOrder((prev) => ({ ...prev, products }));
  };

  const handleProductQuantity = (productId, type) => {
    try {
      let products = updateOrder?.products;

      let total = 0;

      products = products.map((product) => {
        let updatedProduct = {};

        if (product?._id === productId && type === 'increase') {
          const quantity = product?.quantity + 1;

          const subtotal = calculateSubtotal(product?.productId?.price, quantity);

          updatedProduct = { ...product, quantity, subtotal };
        } else if (product?._id === productId && type === 'decrease') {
          const quantity = product?.quantity - 1;

          const subtotal = calculateSubtotal(product?.productId?.price, quantity);

          updatedProduct = { ...product, quantity, subtotal };
        } else {
          updatedProduct = product;
        }

        total += updatedProduct?.subtotal;

        return updatedProduct;
      });

      setUpdateOrder((prev) => ({ ...prev, products, total: total.toFixed(2) }));
    } catch (error) {
      toast.error('Ndodhi një gabim!');
      console.log(error);
    }
  };

  const handleUpdateOrder = async () => {
    try {
      const { _id, products, total, status } = updateOrder;
      const { status: statusCode, data } = await authFetch.patch(`order/${_id}`, { products, total, status });

      if (statusCode === 200) {
        setIsEdit(false);
        setIsUpdated(true);
        toast.success(data?.msg);
      }
    } catch (error) {
      console.log(error);
      toast.error('Ndodhi një gabim! Provo përsëri.');
    }
  };

  const { user } = updateOrder;

  let total = 0;

  return (
    <>
      <div>
        <Dialog open={openModal} onClose={onHandleClose} fullWidth maxWidth="lg" disableEscapeKeyDown>
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: '20px 24px 0 24px' }}>
            <Typography variant="h5">{user?.companyName || `${user?.firstName} ${user?.lastName}`}</Typography>
            <IconButton
              onClick={() => {
                onHandleClose();
                setUpdateOrder(order);
                if (isUpdated) getOrders();
                setIsEdit(false);
              }}
            >
              <Iconify icon="eva:close-outline" width={24} height={24} />
            </IconButton>
          </Stack>

          <DialogContent>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEAD.map((headCell) => (
                        <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                          {headCell.label}
                        </TableCell>
                      ))}
                      {isEdit && <TableCell width="50px" />}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {updateOrder?.products.map((product) => {
                      const { productId, manufacturer, model, price, dimension } = product.productId || {};

                      const subtotal = calculateSubtotal(price, product?.quantity);

                      total += subtotal;

                      return (
                        <TableRow key={productId}>
                          <TableCell align="left">{`${manufacturer} ${model}`}</TableCell>
                          <TableCell align="left">{dimension} </TableCell>
                          <TableCell align="left">{fCurrency(price)} </TableCell>
                          <TableCell align="left">
                            {isEdit ? (
                              <div className="PlusMinusButtons">
                                <button
                                  disabled={product?.quantity === 1 && true}
                                  onClick={() => handleProductQuantity(product?._id, 'decrease')}
                                >
                                  -
                                </button>
                                <span>{product?.quantity}</span>
                                <button onClick={() => handleProductQuantity(product?._id, 'increase')}>+</button>
                              </div>
                            ) : (
                              product?.quantity
                            )}
                          </TableCell>
                          <TableCell align="left">{subtotal}</TableCell>
                          {isEdit && (
                            <TableCell>
                              <IconButton aria-label="delete" onClick={() => handleRemoveProduct(product?._id)}>
                                <Iconify icon="eva:trash-2-outline" />
                              </IconButton>
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}

                    <TableRow>
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell style={{ fontWeight: 'bold' }}>Totali:</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>{fCurrency(total)}</TableCell>
                      {isEdit && <TableCell />}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </DialogContent>
          <DialogActions sx={{ p: '10px 24px 20px 24px' }}>
            {isAdmin && !isEdit && !isCancelled && (
              <>
                {!isConfirmed && (
                  <Button variant="contained" color="success" onClick={() => onHandleConfirm(updateOrder?._id)}>
                    Konfirmo
                  </Button>
                )}

                {!isRejected && (
                  <Button variant="contained" color="error" onClick={() => onHandleReject(updateOrder?._id)}>
                    Refuzo
                  </Button>
                )}

                <Button variant="contained" color="info" onClick={() => setIsEdit(true)}>
                  Edito
                </Button>
              </>
            )}

            {isAdmin && isEdit && (
              <>
                <Button variant="contained" color="success" onClick={handleUpdateOrder}>
                  Ruaj
                </Button>

                <Button
                  variant="contained"
                  color="info"
                  onClick={() => {
                    setUpdateOrder(order);
                    setIsEdit(false);
                  }}
                >
                  Anulo
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
