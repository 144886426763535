import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Stack, TextField, FormControl, MenuItem, Select, InputLabel, FormHelperText } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import toast from 'react-hot-toast';

import { useAppContext } from '../../../context/app-context';

export default function ProfileForm({ userId }) {
  const [currentUser, setCurrentUser] = useState(null);

  const { authFetch } = useAppContext();

  const rolesArray = ['user', 'admin'];

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { data } = await authFetch.get(`user/${userId}`);

        setCurrentUser(data);
      } catch (error) {
        toast.error(error?.response?.data || error?.response?.data?.msg);
      }
    };
    fetchUser();
  }, [userId]);

  useEffect(() => {
    if (currentUser) {
      formik.setValues(currentUser);
    }
  }, [currentUser, userId]);

  const ProfileSchema = Yup.object().shape({
    firstName: Yup.string().min(2, 'Shumë i shkurtër!').max(50, 'Shumë i gjatë!').required('Nevojitet emri'),
    lastName: Yup.string().min(2, 'Shumë i shkurtër!').max(50, 'Shumë i gjatë!').required('Nevojitet mbiemri'),
    companyName: Yup.string().min(2, 'Shumë i shkurtër!').max(50, 'Shumë i gjatë!').required('Nevojitet kompania'),
    email: Yup.string().email('Emaili duhet të jetë valid').required('Nevojitet emaili'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      companyName: '',
      role: '',
      email: '',
      phone: '',
      description: '',
    },
    validationSchema: ProfileSchema,
    onSubmit: (values, { setSubmitting }) => {
      (async () => {
        try {
          const updateUser = {
            firstName: formik.values.firstName,
            lastName: formik.values.lastName,
            companyName: formik.values.companyName,
            role: formik.values.role,
            email: formik.values.email,
            phone: formik.values.phone,
            description: formik.values.description,
          };

          const updatedUser = await authFetch.patch(`user/${userId}`, updateUser);

          if (updatedUser !== undefined) {
            setTimeout(() => {
              navigate('/dashboard/profile', { state: { userId } });
            }, 3000);
            toast.success('Përdoruesi është përditësuar me sukses');
          }
        } catch (error) {
          toast.error(error?.response?.data || error?.response?.data?.msg);
        }
      })();
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                label="Emri"
                {...getFieldProps('firstName')}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />

              <TextField
                fullWidth
                label="Mbiemri"
                {...getFieldProps('lastName')}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />
            </Stack>
            <TextField
              fullWidth
              label="Kompania"
              {...getFieldProps('companyName')}
              error={Boolean(touched.companyName && errors.companyName)}
              helperText={touched.companyName && errors.companyName}
            />
            {/* {user?.role === 'admin' && ( */}
            <FormControl fullWidth error={Boolean(touched.role && errors.role)}>
              <InputLabel id="role-label">Roli</InputLabel>
              <Select id="select-role" label="Roli" {...getFieldProps('role')} sx={{ textTransform: 'capitalize' }}>
                {rolesArray.map((item, index) => (
                  <MenuItem key={index} value={item} sx={{ textTransform: 'capitalize' }}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
              {Boolean(touched.role && errors.role) && <FormHelperText>{errors.role}</FormHelperText>}
            </FormControl>
            {/* )} */}

            <TextField
              fullWidth
              type="email"
              label="Emaili"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              label="Telefoni"
              {...getFieldProps('phone')}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
            />

            <TextField fullWidth type="text" label="Përshkrimi" {...getFieldProps('description')} rows={4} multiline />

            <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting}>
              Përditëso
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
}
