import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

export default function AlertDialogText({ handleClose, handleConfirm, text }) {
  return (
    <div>
      <Dialog
        open
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{text}</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={handleConfirm}>
            Konfirmo
          </Button>
          <Button variant="outlined" onClick={handleClose} color="error">
            Anulo
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
